window.App = window.App || {};
window.App.views = App.views || {};
window.App.modules = App.modules || {};

/**
 * Модуль для  хранилища конфигураций сохраненных фильтров
 *
 * @param w  window
 * @param name название формы
 * @returns {{setItem: setItem, getItem: getItem, removeItem: removeItem, getListNames: getListNames}|*}
 * @constructor
 */
window.App.modules.FilterStorage = function (w, name) {
    var formName = name,
        localStorage = (_checkStorage()) ? w.localStorage : false,
        formData = {},
        methods;

    if (!localStorage) {
        return
    }

    if (localStorage[formName]) {
        formData = JSON.parse(localStorage[formName]);
    }

    function saveInStorage() {
        localStorage[formName] = JSON.stringify(formData);
    }

    function _checkStorage() {
        try {
            return 'localStorage' in w && w['localStorage'] !== null;
        } catch (e) {
            return false;
        }
    }

    methods = {
        setItem: function (key, obj) {
            formData[key] = obj;
            saveInStorage();
        },
        getItem: function (key) {
            var result = null;

            if (formData[key]) {
                result = formData[key];
            }

            return result;
        },
        removeItem: function (key) {
            if (formData[key]) {
                delete formData[key];
                saveInStorage();
            }
        },
        getListNames: function () {
            return Object.keys(formData);
        }

    };

    return methods;
};

/**
 *  view меню спрятанных полей формы
 *
 * @returns {{hide: hide, init: init, reload: reload, remove: remove}|*}
 * @constructor
 */
window.App.views.ActiveFilterView = function () {
    var
        $addFilterBlock = null,
        $addFilterMenu = null,
        methods;

    /**
     *
     * @param $searchForm  блок с формой фильтров в jQuery обертке
     * @param elTitles  object значения лейблы элементов формы , ключи - индекс спрятанного елемента в массиве
     * в родетельской view
     */
    function init($searchForm, elTitles) {
        var html = template(elTitles),
            form = $searchForm.find('form');
        $addFilterBlock = $(html);

        $addFilterBlock.prependTo(form);
        $addFilterMenu = $addFilterBlock.find('.add-filter-menu');

        addEvents($searchForm);
    }

    function template(elTitles) {
        var html = '<div class="dropdown add-filter-menu-wrapper">';

        html += '<button class="btn btn-primary add-filter-btn dropdown-toggle" type="button" data-toggle="tooltip" title="Добавить поля" >+</button>';
        html += '<ul class="dropdown-menu add-filter-menu">';
        for (var i in elTitles) {
            html += '<li data-index="' + i + '"><a href="#">' + elTitles[i] + '</a></li>';
        }
        html += '</ul>';
        html += '</div>';

        return html;
    }

    /**
     * события
     *
     * @param $searchForm блок с формой фильтров в jQuery обертке
     */
    function addEvents($searchForm) {
        $addFilterBlock.find('[data-toggle="tooltip"]').tooltip();

        // запрещаем всплытие , что бы не сработало закрытие блока на документе
        $addFilterBlock.on('click', function (e) {
            e.stopPropagation();
        });

        $addFilterMenu.on('click', 'li', function (e) {
            e.stopPropagation();
            var el = e.currentTarget,
                index = el.getAttribute('data-index');
            // событие на блок с формой фильтров
            $searchForm.trigger('showFormElement', index);
            //удаление пункта из меню
            el.parentNode.removeChild(el);

            hideAddFilterMenu()
        });

        $addFilterBlock.find('.add-filter-btn').on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $searchForm.trigger('onOpenAction', {openAction: 'activeForm'});
            $addFilterBlock.toggleClass('open');
        })
    }

    /**
     *  полностью удалает блок  добавлелния фильтров из дом
     */
    function removeAddMenuBlock() {
        if ($addFilterMenu) {
            $addFilterMenu.off();
        }

        if ($addFilterBlock) {
            $addFilterBlock.off('click');
            $addFilterBlock.find('.add-filter-btn').off();
            $addFilterBlock.remove();
        }

        $addFilterBlock = null;
        $addFilterMenu = null;
    }

    /**
     * прячем меню
     */
    function hideAddFilterMenu() {
        if ($addFilterBlock) {
            $addFilterBlock.removeClass('open');
        }
    }

    /**
     * public methods
     * @type {{hide: hide, init: init, reload: reload, remove: remove}}
     */
    methods = {
        hide: function () {
            hideAddFilterMenu();
        },

        init: function ($el, elTitles) {
            init($el, elTitles);
        },
        reload: function ($el, elTitles) {
            removeAddMenuBlock();
            init($el, elTitles);

        },
        remove: function () {
            removeAddMenuBlock();
        }
    };

    return methods;
};

/**
 * view для формы сохранения фильтра
 *
 * @returns {{init: init, hide: hide}|*}
 * @constructor
 */
window.App.views.SaveFilterView = function () {
    var
        $saveFilterForm,
        $saveFilterBlock,
        methods;

    /**
     * @param $searchForm блок с формой фильтров в jQuery обертке
     * @param $formActionsBlock  блок где размещаются кнопки  save и load - dom элемент jQuery обертке
     */
    function render($searchForm, $formActionsBlock) {
        $saveFilterBlock = $(template());
        $saveFilterBlock.appendTo($formActionsBlock);
        $saveFilterForm = $saveFilterBlock.find('.save-filter-form');
        addEvents($searchForm);
    }

    function template() {
        var html = '<div class="save-filter-wrapper">';
        html += '<div class="save-filter-form">';
        html += '<div class="form-group">';
        html += '<label>Название для набора фильтров</label>';
        html += '<input type="text" class="form-control" id="save-filter-name">';
        html += '</div>';
        html += '<button class="btn btn-primary save-filter-btn">Сохранить</button>';
        html += '</div>';
        html += '<button class="btn btn-primary show-save-filter-btn" data-toggle="tooltip" title="Сохранить набор фильтров"><i class="fa fa-save"></i></button>';
        html += '</div>';
        return html;
    }

    function addEvents($searchForm) {
        $saveFilterBlock.on('click', function (e) {
            e.stopPropagation()
        });

        $saveFilterBlock.find('[data-toggle="tooltip"]').tooltip();

        $saveFilterBlock.find('.show-save-filter-btn').on('click', function () {
            $searchForm.trigger('onOpenAction', {openAction: 'saveForm'});
            $saveFilterForm.toggleClass('active');
        });

        $saveFilterBlock.find('.save-filter-btn').on('click', function () {
            var
                $inputName = $saveFilterForm.find('#save-filter-name'),
                filterName = $inputName.val();

            if (!filterName) {
                alert('Введите имя для набора фильтров');
                return;
            }

            $searchForm.trigger('saveFilterForm', filterName);
            $inputName.val('');
            hideForm()
        })
    }

    function hideForm() {
        $saveFilterForm.removeClass('active')
    }

    methods = {
        init: function ($el, $wrapper) {
            render($el, $wrapper);
        },

        hide: function () {
            hideForm()
        }
    };

    return methods;
};

/** View для формы загрузка удаление фильтров
 *
 * @returns {{init: init, hide: hide, reload: reload}|*}
 * @constructor
 */
window.App.views.LoadFilterView = function () {
    var
        $searchForm,
        $actionsContainer,
        formList,
        $loadListBlock = null,
        methods;

    function init($el, $wrapper, listNames) {
        $searchForm = $el;
        $actionsContainer = $wrapper;
        formList = listNames;

        render();
    }

    function render() {
        $loadListBlock = $(template());
        $loadListBlock.appendTo($actionsContainer);
        addEvents()
    }

    function renderMenu() {
        var menuHtml = listTemplate();
        $loadListBlock.find('.form-group').html(menuHtml);
    }

    function addEvents() {
        $loadListBlock.on('click', function (e) {
            e.stopPropagation()
        });

        $loadListBlock.find('[data-toggle="tooltip"]').tooltip();

        $loadListBlock.find('.show-load-filter-btn').on('click', function () {
            $searchForm.trigger('onOpenAction', {openAction: 'loadForm'});
            $loadListBlock.find('.load-filter-form').toggleClass('active');
        });

        $loadListBlock.find('.load-filter-btn').on('click', handlerLoad);
        $loadListBlock.find('.delete-filter-btn').on('click', handlerDelete)
    }

    function handlerDelete() {
        var filterName = $loadListBlock.find('#loadFilterName').val();

        if (!filterName || filterName === '---') {
            alert('Выберите название фильтра для удаления');
            return;
        }

        $searchForm.trigger('deleteFilter', filterName);
        hideForm()
    }

    function handlerLoad() {
        var filterName = $loadListBlock.find('#loadFilterName').val();

        if (!filterName || filterName === '---') {
            alert('Выберите название фильтра для загрузки');
            return;
        }

        $searchForm.trigger('loadFilter', filterName);
        hideForm()
    }

    function template() {
        var html = '<div class="load-filter-wrapper">';

        html += '<div class="load-filter-form  form">';
        html += '<div class="form-group">';
        html += listTemplate();
        html += '</div>';
        html += '<div>';
        html += '<button class="btn btn-primary load-filter-btn">Загрузить</button>';
        html += '<button class="btn btn-danger delete-filter-btn">Удалить</button>';
        html += '</div>';
        html += '</div>';
        html += '<button class="btn btn-primary show-load-filter-btn" data-toggle="tooltip" title="Загрузить набор фильтров"><i class="fa fa-upload"></i></button>';
        html += '</div>';

        return html;
    }

    function listTemplate() {
        var html = '<select id="loadFilterName" class="form-control">';

        html += '<option>---</option>';
        for (var i = 0; i < formList.length; i++) {
            html += '<option>' + formList[i] + '</option>';
        }

        html += '</select>';
        return html;
    }

    function hideForm() {
        $loadListBlock.find('.load-filter-form').removeClass('active');
    }

    methods = {
        init: function ($el, $wrapper, listNames) {
            init($el, $wrapper, listNames)
        },
        hide: function () {
            hideForm()

        },
        reload: function ($el, $wrapper, listNames) {
            if ($loadListBlock) {
                formList = listNames;
                renderMenu();
            } else {
                init($el, $wrapper, listNames);
            }
        }
    };

    return methods;
};

/**
 *
 * Основная view на блок с фильтрами
 *
 * @param $d document
 * @param $searchForm  jQuery el
 * @constructor
 */
window.App.views.FilterForm = function ($d, $searchForm) {

    var
        formName,
        hiddenElements = {},
        quantityHideEls = 0,
        quantityShowEls = 0,
        activeFilterView = null,
        saveFilterView = null,
        loadFilterView = null,
        storage = null,
        $actionsContainer = null;

    //Если полей меньше 6, то нет смысла активировать плагин
    if ($searchForm.find('.filter-field').length < 6) {
        return;
    }

    /**
     * название формы
     * используется как ключ для сохраения всех конфигурация формы
     */
    formName = $('form', $searchForm).attr('id');

    // инит store
    if (formName) {
        storage = window.App.modules.FilterStorage(window, formName)
    }

    // subviews
    activeFilterView = window.App.views.ActiveFilterView();
    saveFilterView = window.App.views.SaveFilterView();
    loadFilterView = window.App.views.LoadFilterView();

    // проверяем прячем пустые поля формы
    initFormFields();

    if (quantityHideEls > 0) {
        activeFilterView.init($searchForm, getTitlesHiddenFields());
    }

    if (storage) {
        // insert wrapper block save load filter
        $actionsContainer = setWrapperForSaveLoadButtons($searchForm);
        saveFilterView.init($searchForm, $actionsContainer);
        loadFilterView.init($searchForm, $actionsContainer, storage.getListNames());
    }

    if (quantityShowEls === 0) {
        hideSendButton();
    }

    addEvents();

    function addEvents() {
        $searchForm.on('showFormElement', handlerShowFormElement);
        $searchForm.on('saveFilterForm', handlerSaveFilter);
        $searchForm.on('deleteFilter', handlerDeleteFilter);
        $searchForm.on('loadFilter', handlerLoadFilter);


        $searchForm.on('changeFilterStorage', function () {
            loadFilterView.reload($searchForm, $actionsContainer, storage.getListNames());
        });

        $searchForm.on('formChanged', function (e) {
            hiddenElements = {};
            quantityHideEls = 0;
            quantityShowEls = 0;

            initFormFields();
            activeFilterView.reload($searchForm, getTitlesHiddenFields());
        });
        // событие на документе , прячем все формы  при клике
        $d.on('click', function () {
            activeFilterView.hide();

            if (storage) {
                saveFilterView.hide();
                loadFilterView.hide();
            }
        });

        // при открытии одной формы закрываем все другие
        $searchForm.on('onOpenAction', function (e, data) {
            switch (data['openAction']) {
                case 'activeForm':
                    if (storage) {
                        saveFilterView.hide();
                        loadFilterView.hide();
                    }
                    break;
                case 'saveForm':
                    loadFilterView.hide();
                    activeFilterView.hide();
                    break;
                case 'loadForm':
                    activeFilterView.hide();
                    saveFilterView.hide();
                    break;
            }
        });
    }

    function initFormFields() {
        $searchForm.find('.filter-field').each(function (index, el) {
            var inputs = el.querySelectorAll('input'),
                selects = el.querySelectorAll('select');

            if (!isSetFieldValue(inputs) && !isSetFieldValue(selects)) {
                $(el).hide();
                quantityHideEls++;
                hiddenElements[quantityHideEls] = el;
            } else {
                showFilter(el);
                quantityShowEls++
            }
        });
    }

    function handlerShowFormElement(event, index) {
        if (typeof hiddenElements[index] !== 'undefined') {
            showFilter(hiddenElements[index]);

            quantityHideEls--;
            quantityShowEls++;

            delete hiddenElements[index];
        }

        if (quantityShowEls === 1) {
            showSendButton();
        }

        if (quantityHideEls <= 0) {
            activeFilterView.remove();
        }
    }

    function handlerSaveFilter(event, filterName) {
        var formData = {},
            countSave = 0,
            formElements = getFormElements();

        formElements.each(function (index, el) {
            var value,
                name = el.getAttribute('name');

            if (el.tagName === 'SELECT') {
                value = el.value
            } else if (el.tagName === 'INPUT') {

                if (el.type === 'checkbox') {
                    value = el.checked;
                } else if (el.type === 'radio' && el.checked) {
                    value = el.value;
                } else if (
                    el.type !== 'hidden'
                    && el.type !== 'submit'
                    && el.type !== 'reset'
                    && el.type !== 'button'
                ) {
                    value = (el.value) ? el.value.trim() : false;
                }
            }

            if (value) {
                formData[name] = value;
                countSave++;
            }
        });

        if (countSave > 0) {
            storage.setItem(filterName, formData);
            alert('набор фильтров сохранен');

            $searchForm.trigger('changeFilterStorage');

        } else {
            alert('Нет значений в форме для сохранения')
        }
    }

    function handlerLoadFilter(event, filterName) {
        var filterData = storage.getItem(filterName),
            formEls = getFormElements();

        formEls.each(function (i, el) {
            var $el,
                elName = el.name,
                isSetField = (typeof filterData[elName] !== 'undefined'),
                value = (isSetField) ? filterData[elName] : '';

            if (el.tagName === 'SELECT') {
                el.value = value;
            } else if (el.tagName === 'INPUT') {
                if (el.type === 'checkbox') {
                    if (isSetField) {
                        el.setAttribute('checked', 'checked');
                    } else {
                        el.removeAttribute('checked');
                    }
                } else if (el.type === 'radio' && el.value === value) {
                    el.setAttribute('checked', 'checked');
                } else if (
                    el.type !== 'hidden'
                    && el.type !== 'submit'
                    && el.type !== 'reset'
                    && el.type !== 'button'
                ) {
                    $el = $(el);
                    // проверяем или это поле дата с плагином datepicker
                    if ($el.data('datepicker')) {
                        $el.datepicker('update', value);
                        // после изменения поля срабатывает плагин показывет блок с выбором даты - закрываем блок
                        setTimeout(function () {
                            $el.datepicker('hide');
                        }, 500)
                    } else {
                        el.value = value;
                    }
                }
            }
        });

        $searchForm.trigger('formChanged');
    }

    function handlerDeleteFilter(event, filterName) {
        storage.removeItem(filterName);
        $searchForm.trigger('changeFilterStorage');
    }

    function showFilter(el) {
        $(el)
            .css('display', 'inline-block')
            .find('select, input[type!="hidden"]')
            .trigger('focus');

    }

    /**
     * показываем кнопку формы фильтров
     */
    function showSendButton() {
        $searchForm.find('button[type="submit"]').css('display', 'inline-block');
    }

    /**
     * прячем кнопку формы фильтров
     */
    function hideSendButton() {
        $searchForm.find('button[type="submit"]').css('display', 'none');
    }

    /**
     *  получаем все поля формы фильтров
     */
    function getFormElements() {
        return $searchForm.find('.filter-field input, .filter-field select');
    }

    /**
     * проверяет или в наборе єлементов формы есть значение
     *
     * @param els
     * @returns {boolean}
     */
    function isSetFieldValue(els) {
        var
            isSetField = false,
            el,
            i;

        for (i = 0; i < els.length; i++) {
            el = els[i];

            if (isSetField) {
                break;
            }

            if (el.tagName === 'SELECT') {
                isSetField = !!(el.value);
            } else if (el.tagName === 'INPUT') {
                if (el.type === 'checkbox' || el.type === 'radio') {
                    isSetField = isSetField || el.checked;
                } else if (
                    el.type !== 'hidden'
                    && el.type !== 'submit'
                    && el.type !== 'reset'
                    && el.type !== 'button'
                ) {
                    isSetField = !!(isSetField || (el.value && el.value.trim()));
                }
            }
        }

        return isSetField;
    }

    /**
     * поулчаем названия из форм для списка скрытых форм
     * @returns {{}}
     */
    function getTitlesHiddenFields() {
        var fieldTitles = {},
            elLabel,
            elTitle;

        for (var i in hiddenElements) {
            elLabel = hiddenElements[i].querySelector('label');
            elTitle = (elLabel) ? elLabel.innerText : ' - ';

            fieldTitles[i] = elTitle;
        }

        return fieldTitles
    }

    /**
     * вставляем контейнер для кнопок сохранить и загрузить
     *
     * @param $searchForm
     * @returns {jQuery|HTMLElement}
     */
    function setWrapperForSaveLoadButtons($searchForm) {
        var $searchFormActions = $('<div class="search-form-actions"></div>');

        $searchFormActions.appendTo($searchForm);

        return $searchFormActions;
    }
};

$(function () {
    // main
    //Временно плагин не активируется
    // (function () {
    //     const $searchForm = $('.search-active-filter');
    //     if ($searchForm.length === 0) {
    //         return;
    //     }
    //
    //     // init main filter view
    //     window.App.views.FilterForm($(document), $searchForm);
    // }());

    //При отправке данных удаляет пустые значения, чтоб не случалось слишком длинной адресной строки
    $('.search-active-filter form[method^="get"]').submit(function () {
        $(this)
            .find('input[name], select[name]')
            .filter(function () {
                return !this.value;
            })
            .each(function () {
                $(this).attr('disabled', 'disabled')
            });
    });
});
