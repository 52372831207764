import orderCreateForm from './lib/order';
import timer from './lib/timer';

declare global {
    interface JQuery {
        insertAtCaret(myValue: string): void;

        qtip(options: object): void;
    }
}

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        noticeManager: {
            showErrorNotice: (text: string, autoClose: boolean) => void;
            showSuccessNotice: (text: string, autoClose: boolean) => void;
            showInfoNotice: (text: string, autoClose: boolean) => void;
        };
    }
}

$(document).ready(function () {
    $('[rel~=tooltip], [data-toggle~=tooltip]').tooltip();
    $('[rel~=popover], [data-toggle~=popover]').popover({
        trigger: 'hover'
    });

    //Нужен только для того чтоб срабатывали "мульти" data-toggle, типа data-toggle="modal tooltip"
    $(document).on('click.bs.modal.data-api', '[data-toggle~="modal"]', function (e) {
        const $this = $(this);
        const $target = $($this.attr('data-target'));

        if ($this.is('a')) {
            e.preventDefault();
        }

        $target.modal('show');
    });

    $('form[data-submit-confirm]').on('submit', function () {
        return confirm($(this).data('submit-confirm'));
    });

    //TaskQueue
    const $taskQueuePanel = $('#task_queue_panel');

    if ($taskQueuePanel.length > 0) {
        const $contentBlock = $taskQueuePanel.find('.panel-content');
        const $panelControl = $taskQueuePanel.find('.panel-control');

        $panelControl.on('click', function () {
            if ($taskQueuePanel.hasClass('active')) {
                $taskQueuePanel.removeClass('active');
            } else {
                $taskQueuePanel.addClass('active');
                loadContent();
            }
        });

        $taskQueuePanel.on('click', '#refresh-panel', function () {
            loadContent();
        });

        $taskQueuePanel.on('click', '#clear-panel', function () {
            if (confirm('Точно хотите удалить информацию о завершенных задачах?')) {
                $.ajax({
                    url: $(this).attr('data-url'),
                    type: 'POST',
                    dataType: 'html',
                    success: function (result) {
                        if (result === '') {
                            $taskQueuePanel.remove();
                        } else {
                            $contentBlock.html(result);
                        }
                    }
                });
            }
        });

        function loadContent() {
            $contentBlock.html("<img src='/img/zoomloader.gif' width='16' height='16' alt='Загрузка...' /> Загрузка...");
            $contentBlock.load($taskQueuePanel.attr('data-url'), function () {
                $panelControl.find('span').html($contentBlock.find('.badge-info').text());
            });
        }
    }

    /**
     * text templates for editable links
     */
    $('.with-text-templates-block').on('click', '.item-text-template', function (e) {
        e.preventDefault();
        const tplText = $(e.currentTarget).attr('title');
        $(e.delegateTarget).find('textarea').insertAtCaret(tplText);
    });

    initQtip('');

    orderCreateForm();

    timer();

    $('[rel~=mark-row-by-click]').on('click', function () {
        $(this).closest('tr').toggleClass('h');
    });
});

document.addEventListener('DOMContentLoaded', function() {
    // Поиск всех кнопок с атрибутом data-copy-value и добавление обработчика событий клика
    document.querySelectorAll('button[data-copy-value]').forEach(function(button) {
        button.addEventListener('click', function() {
            const textToCopy = this.getAttribute('data-copy-value');

            // Используем Clipboard API для копирования текста в буфер обмена
            navigator.clipboard.writeText(textToCopy).then(function() {
                window.noticeManager.showSuccessNotice("Код заказа " + textToCopy + " скопирован в буфер обмена", true);
            }).catch(function(error) {
                console.error("Произошла ошибка при попытке копирования: ", error);
            });
        });
    });
});

// @ts-ignore
function initQtip(rootSelector: string) {
    let $container = $(document);

    if (rootSelector !== '') {
        $container = $(rootSelector);
    }

    // Make sure to only match links to wikipedia with a rel tag
    $container.find('*[data-qtip-url]').each(function () {
        // We make use of the .each() loop to gain access to each element via the 'this' keyword...
        $(this).qtip({
            content: {
                // Set the text to an image HTML string with the correct src URL to the loading image you want to use
                text: '<img class=\"throbber\" src=\"/img/zoomloader.gif\" alt=\"Загрузка...\" />',
                ajax: {
                    url: $(this).attr('data-qtip-url') // Use the rel attribute of each element for the url to load
                },
                title: {
                    text: $(this).attr('title'), // Give the tooltip a title using each elements text
                    button: true
                }
            },
            position: {
                at: 'center', // Position the tooltip above the link
                my: 'center',
                target: $(window),
                viewport: $(window), // Keep the tooltip on-screen at all times
                effect: false // Disable positioning animation
            },
            show: {
                event: 'mouseover touchstart',
                solo: true // Only show one tooltip at a time
            },
            hide: {
                event: 'mouseleave unfocus',
                effect: false
            },
            style: {
                classes: 'qtip-light qtip-shadow'
            }
        });

        if (('ontouchstart' in window || $(window).width() < 991)) {
            $(this).on('click', function () {
                return false;
            });
        }
    });
}
