window.noticeManager = (function () {
    var _timeInterval = 5000,
        _containerId = 'noticeContainer',
        _template = '<div id="' + _containerId + '" class="notice-container"></div>';

    function _render(text, typeClass, autoClose) {

        var template = '<div class="' + typeClass + ' alert fade in">';

        if (!autoClose) {
            template += '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>';
        }

        template += text;
        template += '</div>';
        return template;
    }

    function _show(text, typeClass, autoClose) {
        var
            html = _render(text, typeClass, autoClose),
            $container = _getContaeinerForNotices(),
            $alertEl;

        $container.prepend(html);

        if (autoClose) {

            $alertEl = $container.find('div:first-child');

            if ($alertEl.length > 0) {

                setTimeout(function () {
                    _hide($alertEl);
                }, _timeInterval)
            }
        }
    }

    function _hide($el) {
        $el.fadeOut(function () {
            $el.remove();
        });

    }

    function _getContaeinerForNotices() {
        var $container = $('#' + _containerId);

        if ($container.length < 1) {
            $('body').append(_template);
            $container = $('#' + _containerId);
        }
        return $container
    }

    return {
        showErrorNotice: function (text, autoClose) {
            var typeClass = 'alert-danger';
            _show(text, typeClass, autoClose);
        },

        showSuccessNotice: function (text, autoClose) {
            var typeClass = 'alert-success';
            _show(text, typeClass, autoClose);
        },

        showInfoNotice: function (text, autoClose) {
            var typeClass = 'alert-warning';
            _show(text, typeClass, autoClose);
        }
    }
}());
