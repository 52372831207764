//Дубль файла с frontend, но с удаленным i18n

export default function () {
    initOrderFieldsByDelivery();
    initWhsDropdown();
    initTabs();
};

function initTabs() {
    const $tabs = $('#receiver_type_tabs');

    $tabs
        .on('shown.bs.tab', function (e) {
            const receiver = $(e.target).attr('data-receiver');
            onChangeReceiverType(receiver);
        });

    onChangeReceiverType($tabs.find('.active a').attr('data-receiver'));
}

function onChangeReceiverType(newReceiverType: string) {
    const $receiverExtraIndividual = $('#receiver_extra_individual');
    const $receiverCompany = $('#receiver_company');

    switch (newReceiverType) {
        case 'normal':
            $receiverExtraIndividual.hide();
            $receiverExtraIndividual.find('input').val('');

            $receiverCompany.hide();
            $receiverCompany.find('input').val('');
            break;
        case 'extra_individual':
            $receiverExtraIndividual.show();

            $receiverCompany.hide();
            $receiverCompany.find('input').val('');
            break;
        case 'company':
            $receiverExtraIndividual.hide();
            $receiverExtraIndividual.find('input').val('');

            $receiverCompany.show();
            break;
    }
}

function initOrderFieldsByDelivery() {
    if ($('#order-form').length == 0) {
        return;
    }

    const $paymentMethod = $('#orderPaymentMethod');
    if ($paymentMethod.length > 0) {
        order_pm();
        $paymentMethod.find('input').change(order_pm);
    }

    order_dm();
    $('#orderDeliveryMethod').find('input').change(order_dm);
}

function initWhsDropdown() {
    if ($('#order-form').length == 0) {
        return;
    }

    const $city = $('#orderCity');
    const $orderCityFullDescription = $('#orderCityFullDescription');
    const $cityRef = $('#orderCityRef');

    if ($city.length == 0 || $cityRef.length == 0) {
        return;
    }

    const whs = whsList($city, $cityRef);
    const cityTip = cityTips($cityRef, $city, $orderCityFullDescription);

    whs.updateList();

    //events
    $city.attr('autocomplete', 'off');
    $city.attr('placeholder', 'Укажите населенный пункт');
    $('<style>#orderCity::placeholder { color: red; }</style>').appendTo('head');

    $('#orderDeliveryMethod').find('input').on('change', function () {
        cityTip.reset();
        whs.updateList();
    });

    $cityRef.on('change', function () {
        whs.updateList();
    });

    $city.on('keyup', function (e) {
        if (e.key != 'shift' && e.key != 'ctrl' && e.key != 'alt' && e.key != 'left window key') {
            cityTip.update();
        }
    });
}

//выбор склада
function whsList(objCity: JQuery, objCityRef: JQuery) {
    let cityRef: string;
    let currentVal: any;
    let keyCache: string;
    let errorMsg: boolean;
    let arrCache: { [key: string]: any } = {};

    const urlUpdate = $('#order-form').data('url-whs');

    const objWhsNum = init('#order-whs-num');
    const objWhsList = $('#whs-list');
    const objLoader = $('#whs-loader');
    const objNoWhs = $('#no-whs');

    if (objWhsNum.closest(".input").hasClass('error')) {
        errorMsg = true;
    }

    objWhsList.on('change', function (e) {
        e.stopPropagation();
        objWhsNum.val($(this).val());
    });

    function init(id: string): JQuery {
        let el = $(id);

        currentVal = el.val();
        el.after('<select id="whs-list" style="display:none;" class="form-control"></select><p id="no-whs" style="color:darkred">Склады в указанном населенном пункте не найдены</p><div id="whs-loader" class="al-h" style="display: none"></div>');
        el.replaceWith('<input type="hidden" name="' + el.attr('name') + '" id="order-whs-num" />');

        return $(id);
    }

    function update(): void {
        const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

        if (dm != DeliveryMethod.NP_WHS && dm != DeliveryMethod.NP_POSTOMAT && dm != DeliveryMethod.UKRP) {
            return;
        }

        let key: string;
        const newCity = $.trim(objCity.val());
        const newCityRef = $.trim(objCityRef.val());

        if (validateValues(newCity) && newCityRef) {
            key = newCityRef + '|' + dm;

            if (newCityRef == cityRef) {
                currentVal = objWhsNum.val();
            }

            cityRef = newCityRef;

            keyCache = key;

            before();

            if (!arrCache[keyCache]) {
                load();
            } else {
                renderSelect();
            }
        } else {
            hideList();
            errorUpdate();
        }
    }

    function load(): void {
        if (cityRef == undefined) {
            return;
        }

        const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

        let deliveryMethodCode = 'np';
        if (dm === DeliveryMethod.UKRP) {
            deliveryMethodCode = 'ukrposhta';
        }

        objLoader.show();

        let category = undefined;
        if (dm === DeliveryMethod.NP_WHS) {
            category = 0;
        } else if (dm === DeliveryMethod.NP_POSTOMAT) {
            category = 1;
        }

        const filterParams = {
            service: deliveryMethodCode,
            locationUuid: cityRef,
            category: category,
        };

        $.ajax({
            url: urlUpdate,
            type: 'GET',
            dataType: 'json',
            data: {filter: filterParams},
            cache: true,
            success: function (result) {
                if (result) {
                    arrCache[keyCache] = result;
                    renderSelect();
                } else {
                    errorUpdate();
                }
                objLoader.hide();
            }
        });
    }

    function renderSelect(): void {
        const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

        let i = 0;
        $.each(arrCache[keyCache], function (_, v) {
            let whsTitle = v.address;
            if (dm === DeliveryMethod.UKRP) {
                whsTitle = v.uuid + ': ' + whsTitle;
            }

            objWhsList.append('<option value="' + v.uuid + '">' + escapeHtml(whsTitle) + '</option>');
            if (i == 0) {
                objWhsNum.val(v.uuid);
            }
            i++;
        });

        if (i > 0) {
            successUpdate();
        } else {
            errorUpdate();
        }

        if (currentVal) {
            setValue(currentVal);
            currentVal = false;
        }
    }

    function validateValues(city: string): boolean {
        return ((/^[а-яіїєґ][а-яіїєґ'\d]{2,}([-\s(][()а-яіїєґ'\d]+)?$/i.test(city)));
    }

    function before(): void {
        hideList();
        objNoWhs.hide();
    }

    function errorUpdate(): void {
        objNoWhs.show();
    }

    function successUpdate(): void {
        objWhsList.show();

        if (errorMsg) {
            const parent = objWhsNum.closest("div.input");
            parent.removeClass('error');
            parent.children('.error-message').remove();
            errorMsg = false;
        }
    }

    function setValue(val: string): void {
        if ($('option', objWhsList).is('option[value="' + val + '"]')) {
            $('option[value=' + val + ']', objWhsList).prop('selected', 'selected');
            objWhsNum.val(val);
        }
    }

    function hideList(): void {
        objWhsList.hide().html('');
        objWhsNum.val('');
    }

    return {
        'updateList': update
    };
}

// подсказки город
function cityTips(cityRef: JQuery, city: JQuery, orderCityFullDescription: JQuery) {
    const url: string = $('#order-form').data('url-cities');

    let arrCache: { [key: string]: CityItemMap } = {};

    city.after('<ul class="order_tips"></ul>');
    const orderTips = city.next('ul.order_tips');

    orderTips.on('click', 'li', function (e) {
        e.stopPropagation();

        const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

        const rel: number = parseInt($(e.currentTarget).attr('rel'));
        const data = arrCache[city.val() + '|' + dm];
        let cityItem: CityItem;

        if (data !== undefined && data[rel] !== undefined) {
            cityItem = data[rel];

            if (cityItem) {
                city.attr('rel', rel);
                city.val(cityItem.name);
                cityRef.val(cityItem.uuid);

                orderCityFullDescription.html(_renderCityItemContent(cityItem) + '<br><br>');
            }

            cityRef.trigger('change');
        }
        _hide();
    });

    $('body').on('click', function () {
        _hide(orderTips.is(':visible'));
    });

    function updateTips(): void {
        city.removeAttr('rel');

        const val = $.trim(city.val());

        if (!validateValues(val)) {
            _hide(true);
            return;
        }
        orderTips.html('');

        const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

        if (!arrCache[val + '|' + dm]) {
            loadData(val);
        } else {
            render(arrCache[val + '|' + dm]);
        }
    }

    type CityItem = {
        uuid: string;
        name: string;
        koatuu?: number;
        type?: string;
        region: string;
        regionKoatuu: number;
        district: string;
    };

    interface CityItemMap {
        [key: number]: CityItem;
    }

    function loadData(val: string): void {
        const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

        let deliveryMethodCode = 'np';
        if (dm === DeliveryMethod.UKRP) {
            deliveryMethodCode = 'ukrposhta';
        }

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            data: {filter: {service: deliveryMethodCode, locationName: val}},
            cache: true,
            success: function (result: CityItemMap) {
                if (result) {
                    arrCache[val + '|' + dm] = result;
                    render(result);
                }
            }
        });
    }

    function render(cityItems: CityItemMap): void {
        let count = 0;

        $.each(cityItems, function (ind, cityItem: CityItem) {
            orderTips.append('<li rel="' + ind + '">' + _renderCityItemContent(cityItem) + '</li>');
            count++;
        });

        if (count > 0) {
            orderTips.show();
        } else {
            _hide();
        }
    }

    function validateValues(val: string): boolean {
        return !!((/^[а-яіїєґ][-()а-яіїєґ'\d\s]*$/i.test(val)));
    }

    function _hide(withReset: boolean = false) {
        if (withReset) {
            _resetCity();
        }

        orderTips.hide();
    }

    function _makeLocationTitle(location: CityItem): string {
        let prefix = '';

        switch (location.type) {
            case 'CITY':
                prefix = 'м. ';
                break;
            case 'URBAN_TOWNSHIP':
                prefix = 'смт ';
                break;
            case 'TOWNSHIP':
                prefix = 'с-ще ';
                break;
            case 'VILLAGE':
                prefix = 'с. ';
                break;
        }

        return prefix + location.name;
    }

    function _renderCityItemContent(cityItem: CityItem): string {
        const cityTitle = _makeLocationTitle(cityItem);
        let cityDesc = escapeHtml(cityItem.region + ' обл.');
        if (cityItem.district) {
            cityDesc += ', ' + escapeHtml(cityItem.district + ' р-н');
        }

        return '<strong>' + escapeHtml(cityTitle) + '</strong> (' + cityDesc + ')';
    }

    function _resetCity() {
        city.val('');
        cityRef.val('');
        orderCityFullDescription.html('');
        cityRef.trigger('change');
    }

    return {
        'update': updateTips,
        'reset': _resetCity
    }
}

// noinspection JSUnusedGlobalSymbols
enum PaymentMethod {
    PREPAY = 3,
    PREPAY_BANK = 5,
    POD = 4,
}

// noinspection JSUnusedGlobalSymbols
enum DeliveryMethod {
    NP_COURIER = 1,
    NP_WHS = 2,
    UKRP = 3,
    AVTOLUX = 4,
    NOCHEXP = 5,
    VSEPLUS_COURIER = 6,
    NP_POSTOMAT = 7,
    UKRP_COURIER = 8,
}

function order_pm(): void {
    const pmv: PaymentMethod = parseInt($('#orderPaymentMethod').find('input:checked').val()) as PaymentMethod;

    if (pmv == PaymentMethod.POD) {
        $('#np-hint').show();
    } else {
        $('#np-hint').hide();
    }
}

function order_dm(): void {
    const dm: DeliveryMethod = parseInt($('#orderDeliveryMethod').find('input:checked').val()) as DeliveryMethod;

    const allFields = [
        '#r_zip_code',
        '#r_city',
        '#r_whs',
        '#r_street',
        '#r_house_num',
        '#r_apartment_num',
    ];

    const addressFieldsInfo = getAddressFieldsForDeliveryMethod(dm);

    allFields.forEach(function (fieldId: string) {
        const $field = $(fieldId);

        if (addressFieldsInfo.fields.includes(fieldId)) {
            $field.show();

            if (addressFieldsInfo.required.includes(fieldId)) {
                $field.addClass('required');
            } else {
                $field.removeClass('required');
            }
        } else {
            $field.hide();
        }
    });
}

interface AddressFields {
    fields: string[];
    required: string[];
}

function escapeHtml(str: string) {
    const p = document.createElement('p');
    p.appendChild(document.createTextNode(str));
    return p.innerHTML;
}

function getAddressFieldsForDeliveryMethod(deliveryMethod: DeliveryMethod): AddressFields {
    const requiredAlways = ['#r_city'];

    switch (deliveryMethod) {
        case DeliveryMethod.NP_COURIER:
            return <AddressFields>{
                fields: [
                    ...requiredAlways,
                    '#r_street',
                    '#r_house_num',
                    '#r_apartment_num',
                ],
                required: [
                    ...requiredAlways,
                    '#r_street',
                    '#r_house_num',
                ],
            };
        case DeliveryMethod.NP_WHS:
        case DeliveryMethod.NP_POSTOMAT:
        case DeliveryMethod.UKRP:
            return <AddressFields>{
                fields: [
                    ...requiredAlways,
                    '#r_whs',
                ],
                required: [
                    ...requiredAlways,
                    '#r_whs',
                ],
            };
        case DeliveryMethod.VSEPLUS_COURIER:
            return <AddressFields>{
                fields: [
                    ...requiredAlways,
                    '#r_street',
                    '#r_house_num',
                    '#r_apartment_num',
                ],
                required: [
                    ...requiredAlways,
                    '#r_street',
                    '#r_house_num',
                ],
            };
    }
}
