export default function () {
    const readMoreBlocks = document.querySelectorAll('[data-timer]');

    Array.prototype.forEach.call(readMoreBlocks, function (el: Element) {
        const futureDate = new Date(el.getAttribute('data-timer'));
        const futureSeconds = Math.round(futureDate.getTime() / 1000);
        const nowSeconds = Math.round((new Date()).getTime() / 1000);

        let duration: number = futureSeconds - nowSeconds;
        if (duration < 0) {
            duration = 0;
        }

        startTimer(duration, el);
    });
};

function startTimer(duration: number, display: Element) {
    let timer = duration;
    let hours;
    let minutes;

    const intervalId = setInterval(function () {
        hours = Math.floor((timer / 60) / 60);
        minutes = Math.floor((timer % 3600) / 60);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        const word = (hours > 0 ? 'ч.' : 'мин.');
        const result = "[" + hours + ":" + minutes + " " + word + "]";

        if (display.textContent != result) {
            display.textContent = result;
        }

        if (--timer < 0) {
            timer = duration;
            clearInterval(intervalId);
        }
    }, 1000);
}
