// noinspection JSUnusedGlobalSymbols
jQuery.fn.extend({
    insertAtCaret: function (myValue: string) {
        return this.each(function () {
            if (document.hasOwnProperty('selection')) {
                this.focus();
                // @ts-ignore
                const sel = document.selection.createRange();
                sel.text = myValue;
                this.focus();
            } else if (this.selectionStart || this.selectionStart === '0') {
                const startPos = this.selectionStart;
                const endPos = this.selectionEnd;
                const scrollTop = this.scrollTop;

                this.value = this.value.substring(0, startPos) + myValue + this.value.substring(endPos, this.value.length);
                this.focus();
                this.selectionStart = startPos + myValue.length;
                this.selectionEnd = startPos + myValue.length;
                this.scrollTop = scrollTop;
            } else {
                this.value += myValue;
                this.focus();
            }
        })
    }
});
