//form.emulate
jQuery(function ($) {
    $.extend({
        form: function (url: string, data: any, method: any) {
            if (method === undefined) {
                method = 'POST';
            }

            if (data === undefined) {
                data = {};
            }

            const form = $('<form>').attr({
                method: method,
                action: url
            }).css({
                display: 'none'
            });

            const addData = function (name: string, data: any) {
                if ($.isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        addData(name + '[]', data[i]);
                    }
                } else if (typeof data === 'object') {
                    for (let key in data) {
                        if (data.hasOwnProperty(key)) {
                            addData(name + '[' + key + ']', data[key]);
                        }
                    }
                } else if (data !== null) {
                    form.append($('<input>').attr({
                        type: 'hidden',
                        name: String(name),
                        value: String(data)
                    }));
                }
            };

            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    addData(key, data[key]);
                }
            }

            return form.appendTo('body');
        }
    });
});
