$(document).on("click", "[data-toggle~='translate']", function (e) {
    var langIdTo = $(this).data('lang-id-to');
    var langIdFrom = (langIdTo === 1 ? 2 : 1);
    var $form = $('#' + $(this).data('form-id'));
    var $needTranslateFields = $form
        .find('input[type=text][name*="I18n[' + langIdTo + ']"], textarea[name*="I18n[' + langIdTo + ']"]')
        .filter(function () {
            return $(this).val() === "";
        });

    var textByField = {};

    $needTranslateFields.each(function () {
        var oppositeFieldName = $(this).attr('name').replace('[' + langIdTo + ']', '[' + langIdFrom + ']');
        var $oppositeField = $form.find('*[name="' + oppositeFieldName + '"]');

        var oppositeValue = $oppositeField.val();

        if (oppositeValue === '') {
            return;
        }

        textByField[$(this).attr('id')] = oppositeValue;
    });

    if ($.isEmptyObject(textByField)) {
        return;
    }

    $("[data-lang-id-to='" + langIdTo + "']")
        .find('[data-fa-i2svg]')
        .toggleClass('fa-language')
        .toggleClass('fa-sync fa-spin');

    $.ajax({
        url: '/index.php?r=translate',
        data: {'fields': textByField, 'langIdFrom': langIdFrom, 'langIdTo': langIdTo},
        type: 'POST',
        success: function (res) {
            $("[data-lang-id-to='" + res.langId + "']")
                .find('[data-fa-i2svg]')
                .toggleClass('fa-language')
                .toggleClass('fa-sync fa-spin');

            $.each(res.fields, function (fieldId, value) {
                $('label[for="' + fieldId + '"]').append(document.createTextNode(' (требует сохранения)'));

                if (window.EasyMDEInstances && window.EasyMDEInstances[fieldId] !== undefined) {
                    window.EasyMDEInstances[fieldId].value(value);
                } else if (window.CKEDITOR && window.CKEDITOR.dom && typeof CKEDITOR !== undefined && CKEDITOR.instances[fieldId] !== undefined) {
                    CKEDITOR.instances[fieldId].setData(value);
                } else {
                    $('#' + fieldId, $form).val(value);
                }
            });
        },
        error: function (jqXHR) {
            alert('Ошибка: ' + jqXHR.responseJSON.message);
        }
    });
});
